import React from 'react'

export default function Ora() {
  return (
    <iframe width="560" height="315" src="https://www.youtube.com/embed/a14pnNiNDNA?si=vojsXWnu2eLmzWbx" 
        title="YouTube video player" frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen>
    </iframe>
  )
}
